import { async } from '../../util';
import {
	RPLY_COMMENT
} from '../../../common/v5/constants';
import { ERRAND_COLLABORATION_STATUS } from '../../reducers/wire';
import {
	keyGetChatAreas,
	keyForwardChat,
	keyGetChatAgents,
	keyGetChatCandidates,
	keyGetAgentListInChat,
} from '../../constants/keys';
import {
	errand,
	errandUploadAnswerAttachment
} from './errand.js';
import AgentSocket from '../../../common/v5/agentsocket';
import AgentWs from '../../../common/v5/socket/message';
import {
	CHAT_SET_SEEN,
	evtCHAT_MESSAGES_SEEN,
	evtREFRESH_DATA,
	evtCHAT_SUBSCRIBE_AGENT_PRESENCE,
	evtCHAT_SET_AGENT,
	evtCHAT_FINISH_SESSION,
	evtCHAT_SEND_SOCIAL_MEDIA_EMOTICON
} from '../../constants/constants';
import {
	togglePopAlert,
} from '../hmf';

const getChatAreas = (exclude, sessionId) => () => $.ajax({
	url: '/socket/agent.api/getAreas',
	method: 'GET',
	data: {
		exclude: exclude,
		sid: sessionId,
	},
});
export const forwardChat = (to, id, sessionId) => () => $.ajax({
	url: '/socket/agent.api/forward',
	data: {
		"session": sessionId,
		to,
		id,
	},
	type: "GET",
});

export const fetchChatAreaCandidates = (exclude,sessionId) => async(
	getChatAreas(exclude,sessionId),
	errand[keyGetChatAreas]
);

export const forwardChatToArea = (sessionId, areaId) => async(
	forwardChat('area', areaId, sessionId),
	errand[keyForwardChat]
);

const getChatAgents = (sessionId) => () => $.ajax({
	url: '/socket/agent.api/getAgents',
	method: 'GET',
	data: {
		session: sessionId,
	},
});

export const satisfactionChatAction = (sessionId, rating, content) => () => $.ajax({
	url: '/socket/external.api/agentchatrating',
	data: {
		"session": sessionId,
		"rating": rating,
		"content": content,
	},
	type: "POST",
});

export const handleSocialChatEmoticon = (action, messageId, sessionId, channel) => () =>{
	let NewSocket = AgentSocket;
	if(features["chat.enable-new-chat"]) {
		NewSocket = AgentWs;
	}
	NewSocket.SendEvent(evtCHAT_SEND_SOCIAL_MEDIA_EMOTICON, {
		"action": action,
		"messageId": messageId,
		"channel": channel,
		"sessionId": sessionId,
	}, rs =>{
		console.log("RS =>", rs);
		return;
	});
};

export const fetchChatAgentCandidates = (sessionId) => async(
	getChatAgents(sessionId),
	errand[keyGetChatAgents]
);

export const forwardChatToAgent = (sessionId, agentId) => async(
	forwardChat('agent', agentId, sessionId),
	errand[keyForwardChat]
);

const getAgentList = (p) => () => $.ajax({
	url: '/socket/agent.api/agentlist',
	type: 'POST',
	cache: false,
	data: p,
});

export const getChatCandidates = () => async(
	getAgentList({
		area: 0,
		groupByArea: true,
		includeOffline: true,
		sessionId: 0
	}),
	errand[keyGetChatCandidates]
);

// Agents that in chat session
export const getAgentListInChat = (sid) => async(
	getAgentList({
		area: 0,
		groupByArea: false,
		includeOffline: true,
		sessionId: sid
	}),
	errand[keyGetAgentListInChat]
);

// agent presence
let subscribeCount = 0;
const updateSubscription = () => {
	if (subscribeCount == 0 || subscribeCount == 1) {
		AgentSocket.SendEvent(evtCHAT_SUBSCRIBE_AGENT_PRESENCE, {
			// sessionId is not really required for subscribing to agent
			// presence, give one anyway to prevent backend from
			// complaining that it's missing.
			sessionId: -99,
			subscribe: subscribeCount == 1
		});
	} else {
		// we have already subscribed, do nothing
	}
}
export const subscribeAgentPresence = () => {
	subscribeCount++;
	updateSubscription();
}
export const unsubscribeAgentPresence = () => {
	if (subscribeCount == 0) {
		return;
	}
	subscribeCount--;
	if (subscribeCount <= 0) {
		subscribeCount = 0;
		updateSubscription();
	}
}

export const setAgents = (arg, cb) => {
	AgentSocket.SendEvent(evtCHAT_SET_AGENT, arg, ack => cb(ack));
}

export const chatSetSeenMessages = (dispatch, chat, ids) => {
	let NewSocket = AgentSocket;
	if(features["chat.enable-new-chat"]) {
		NewSocket = AgentWs;
	}
	dispatch({type:CHAT_SET_SEEN,chat,ids});
	NewSocket.SendEvent(evtCHAT_MESSAGES_SEEN, { sessionId: chat.sessionId, ids }, estr => {
		if (estr) {
			let msg;
			if (estr == 'ERR_TRY_AGAIN') {
				msg = I('An error occurred, please retry');
			} else {
				msg = I('An error occurred. {ERROR}').replace('{ERROR}', estr);
			}
			dispatch(togglePopAlert(msg));
			return;
		}
	});
}

export const playSoundNotif = () => {
	const sound = process.env.CLOUDFRONT_URL + '/sounds/alert1.mp3';
	const audio = new Audio(sound);
	var promise = audio.play();
	if (promise !== undefined) {
		promise.then(response => {
		}).catch(error => {
			// Autoplay was prevented.
			console.log("dbg: Sound notification error : ", error);
		});
	}
}

export const doUploadChatTempFile = (file) => (dispatch, getState) => {
	let state = getState(), ec =state.app.errand.chat, sessionId = ec.sessionId, sessionSecret = ec.Secret;
	let reply = state.app.errand.ui.reply.current;

	if(reply !== RPLY_COMMENT) {
		let fd = new FormData();
		let boundary = Math.floor(Math.random() * 6)+ '-'+ Math.floor(''+new Date() / 1000) ;
		fd.append( 'uploadfile', file );
		fd.append( 'session', sessionId);
		fd.append( 'sessionSecret', sessionSecret);
		fd.append( 'eventType', 'drag');
		fd.append( 'fileNameOnly', file.name);
		fd.append( 'random', parseFloat(boundary));

		return dispatch(errandUploadAnswerAttachment(fd))
		.then(results => {
			let data = results;
			if (results.length) {
				data = results[0];
				data.download = results[0].download;
				return data;
			}
			data.download = results.download;
			return data;
		});
	}
};

const refreshData = (sessionId, field) => {
	let NewSocket = AgentSocket;
	if(features["chat.enable-new-chat"]) {
		NewSocket = AgentWs;
	}
	NewSocket.SendEvent(
		evtREFRESH_DATA,
		{ sessionId, field }
	);
};

export const refreshChatErrandCollaboration = sessionId => refreshData(
	sessionId,
	ERRAND_COLLABORATION_STATUS
);