import {
	M_REVIEW_KEYWORDS,
	M_REVIEW_AGENTS,
	M_TAG,
	M_ROUTING_KEYWORDS,
	M_ROUTING_AUTOTAGS,
	M_CALL_RECORDINGS,
	M_BLACKLIST,
	M_BLACKLISTRESP,
	M_WHITELIST,
	M_LLM_TOOL_MANAGER
} from '../../common/v5/constants';
import {
	ADMIN_INSERT_EDITOR_CONTENT,
	FILTER_ADMIN_LIST,
	GET_ADMIN_DATA_FROM_LIST,
	RESET_ADMIN_INPUT,
	SET_ADMIN_INPUT,
	SET_ADMIN_LIST_FILTER,
	SET_ADMIN_VIEW,
	SET_AGENT_AVATAR_PREVIEW,
	SET_AGENT_INPUT,
	TOGGLE_ADMIN_EDIT,
	TOGGLE_ADMIN_FORM_TYPE,
	SET_ADMIN_COLUMN_WIDTH,
	TOGGLE_ADMIN_SIDEBAR,
	TOGGLE_AGENT_EDIT,
	UPLOAD_AGENT_IN_PROGRESS,
	SET_ADMIN_CURRENT_SORT_FIELD,
	SET_ADMIN_CURRENT_SORT_ORDER,
	SET_AGENT_SOUND_OUTPUT_LIST,
	SHOW_EMAIL_TEST_POPUP,
	RESET_EMAIL_AVAILABILITY,
	SHOW_MERGE_CONTACT_CARD_POPUP,
	SHOW_ADMIN_HIGHLIGHT,
	APPEND_SUB_TAGS,
	REMOVE_SUB_TAGS,
	ADMIN_ACTION_ALERT,
	SIP_TRUNK_SET_MATCH,
	CALL_UPDATE_IVR_CALLFLOW,
	CALL_UPDATE_IVR_CALLFLOW_VIEW,
	CALL_REMOVE_IVR_KEY,
	UPDATE_AGENT_SKILL,
	ADMIN_ACTION_STATUS,
	RESET_FILTER_ADMIN
} from '../constants/constants';
import {
	allOrgAreasToAreaIDsString,
	convertListToCommaSeperatedString
} from '../../common/helpers';
import { createDirectActionCreator } from '../util';
import { wrapProtectReplyContentMarker } from './common';
import { adminStateActionMap } from '../reducers/admin';
import { fetchAdminList, onceDefaultAgentList } from './async/admin';
import { onlyActiveAreasSelector } from '../selectors/workflow';
import { getAgentListSelector } from '../selectors/admin';

export const setAdminListFilter = (field, value) => adminStateActionMap[SET_ADMIN_LIST_FILTER]({field, value});

const doInsertEditorContent = createDirectActionCreator(ADMIN_INSERT_EDITOR_CONTENT);

export const insertEditorContent = content => (dispatch, getState) => {
	dispatch(doInsertEditorContent(wrapProtectReplyContentMarker(
		content
		, getState()
	)));
};

export const resetInsertionEditorContent = () => doInsertEditorContent("");

export const toggleAdminSidebar = (toggle) => ({
	type: TOGGLE_ADMIN_SIDEBAR,
	payload: toggle
});

const doChangeAdminView = (view) => ({
	type: SET_ADMIN_VIEW,
	payload: view
});

const setAgentFilter = (
	dispatch,
	getState
) => dispatch(setAdminListFilter(
	"selectedAgentFilter",
	convertListToCommaSeperatedString(getAgentListSelector(getState()))
))

const resetFilterBeforeFetchList = view => (dispatch, getState) => {
	if (view === M_REVIEW_KEYWORDS || view === M_TAG || view === M_ROUTING_KEYWORDS ||
		view === M_ROUTING_AUTOTAGS || M_CALL_RECORDINGS || view === M_BLACKLIST || view === M_BLACKLISTRESP || view === M_LLM_TOOL_MANAGER || view === M_WHITELIST) {
		dispatch(setAdminListFilter(
			"selectedAreaFilter"
			, allOrgAreasToAreaIDsString(onlyActiveAreasSelector(getState()))
		));
	}
	if (view !== M_REVIEW_AGENTS && view !== M_CALL_RECORDINGS) {
		return dispatch(fetchAdminList(view,null,true))
	}
	return dispatch(onceDefaultAgentList())
		.then(() => { setAgentFilter(dispatch, getState) })
		.then(() => dispatch(fetchAdminList(view)))
};

export const resetAdminFilter = (view) => ({
	type: RESET_FILTER_ADMIN,
	payload: { view }
});

export const changeAdminView = view => (dispatch, getState) => {
	// TODO: the promise usage here look useless because it always resolve
	// synchronosly as resolve always trigger and do-change-admin-view is
	// synchronos.

	const currentView = getState().app.admin.ui.view;
	if(currentView === view) {
		return;
	}
	return new Promise((resolve, reject) => {
		if (view) {
			resolve(dispatch(doChangeAdminView(view)));
		} else {
			resolve();
		}
	})
	.then(() => {
		dispatch(resetFilterBeforeFetchList(view));
	});
}

export const toggleAgentEdit = (show, isPref) => ({
	type: TOGGLE_AGENT_EDIT,
	payload: {show, isPref}
});

export const showAgentPreferences = () => toggleAgentEdit(true, true);

export const changeAvatarPreview = (img, isPref) => ({
	type: SET_AGENT_AVATAR_PREVIEW,
	payload: {img, isPref}
});

export const setAgentInput = (field, value, isPref) => ({
	type: SET_AGENT_INPUT,
	payload: ({field, value, isPref})
});

export const toggleAdminEdit = (id, show, pop, list) => ({
	type: TOGGLE_ADMIN_EDIT,
	payload: ({id, show, pop, list})
});

export const toggleAdminFormType = (formType) => ({
	type: TOGGLE_ADMIN_FORM_TYPE,
	payload: {formType}
});
export const setAdminColumnWidth = (width) => ({
	type: SET_ADMIN_COLUMN_WIDTH,
	payload: width
});

export const setAdminInput = (field, value, key, extraKey) => ({
	type: SET_ADMIN_INPUT,
	payload: ({field, value, key, extraKey})
});

export const resetAdminInput = (view) => ({
	type: RESET_ADMIN_INPUT,
	payload: ""
});

export const getAdminDataFromList = (id, list, view) => ({
	type: GET_ADMIN_DATA_FROM_LIST,
	payload: ({id, list, view})
})

export const filterAdminList = (view, value, field) => ({
	type: FILTER_ADMIN_LIST,
	payload: ({view, value, field})
});

export const uploadAgentInProgress = (wip) => ({
	type: UPLOAD_AGENT_IN_PROGRESS,
	payload: {wip}
});

export const doAppendSubTags = (tag, subtags, list) => ({
	type: APPEND_SUB_TAGS,
	payload: {tag, subtags, list}
});

export const appendSubTags = (tag, subtags) => (dispatch, getState) => {
	const adminSt = getState().app.admin;
	const list = adminSt.tagsList.data ? adminSt.tagsList.data.tags : [];
	return dispatch(doAppendSubTags(tag, subtags, list));
};

export const removeSubTags = (subtag) => (dispatch, getState) => {
	const adminSt = getState().app.admin;
	const list = adminSt.tagsList.data ? adminSt.tagsList.data.tags : [];
	return dispatch({
		type: REMOVE_SUB_TAGS,
		payload: {subtag, list}
	});
}

export const adminActionAlert = (error) => ({
	type: ADMIN_ACTION_ALERT,
	payload: {error}
});

//status can be:
//{ status: 1, msg: I("Saving...")}
//{ status: 2, msg:  I("Saved")}
//{ status: 3, msg: "I("Error saving...")"}
export const adminActionStatus = (status) => ({
	type: ADMIN_ACTION_STATUS,
	payload: status
});

export const sortAdminTableFields = (value) => ({
	type: SET_ADMIN_CURRENT_SORT_FIELD,
	payload: value
})

export const sortAdminTableOrder = (value) => ({
	type: SET_ADMIN_CURRENT_SORT_ORDER,
	payload: value
})

export const resetEmailAvail = () => ({
	type: RESET_EMAIL_AVAILABILITY,
	payload: true
})

export const checkSoundOutputDevices = () => (dispatch, getState) => {
	let listOutDevices = [];
	if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
		console.log("enumerateDevices() not supported.");
		return;
	}
	//if Use SIP Call, we assume audio devices are allowed to
	//be used in the browser
	//if not, it will ask for user permission first
	navigator.mediaDevices.getUserMedia({audio: true, video: false})
	.then(function() {
		navigator.mediaDevices.enumerateDevices()
		.then(function(devices) {
			devices.forEach(function(device) {
				if(device.kind === "audiooutput") {
					listOutDevices.push(device);
				}
			});
			console.log("dbg: List of audio output devices -> ", listOutDevices);
			return dispatch(updateSoundOutput(listOutDevices));
		})
		.catch(function(err) {
			console.log("Error getting audio output devices:", err.name + ": " + err.message);
			return;
		});
	})
};

const updateSoundOutput = (devices) => ({
	type: 	SET_AGENT_SOUND_OUTPUT_LIST,
	payload: devices
});

export const showEmailTestPopup = createDirectActionCreator(SHOW_EMAIL_TEST_POPUP)

export const closeTestEmailPopup = () => showEmailTestPopup(false)

export const setIVRCallflowAdminInput = (param) => ({
	type: CALL_UPDATE_IVR_CALLFLOW
	, payload: {
		id: param.id,
		field: param.field,
		level: param.level,
		value: param.value,
		parentId: param.parentId,
		ivrId: param.ivrId
	}
});

export const setCallflowView = (ivrId, id, level) => ({
	type: CALL_UPDATE_IVR_CALLFLOW_VIEW
	, payload: {ivrId, id, level}
});

export const removeCallflowKey = (id) => ({
	type: CALL_REMOVE_IVR_KEY
	, payload: {id}
});

export const showMergeContactCardPopup = createDirectActionCreator(SHOW_MERGE_CONTACT_CARD_POPUP)

export const setAdminHighlight = createDirectActionCreator(SHOW_ADMIN_HIGHLIGHT)

export const setSipTrunkMatch = (field, value, isRemove) => ({
	type: SIP_TRUNK_SET_MATCH
	, payload: {field: field, value: value, isRemove: isRemove}
});

export const updateAgentSkill = (skill, isRemove) => ({
	type: UPDATE_AGENT_SKILL,
	payload: {skill, isRemove}
});
